<template>
  <section>
    <!-- <Loader :isLoading="isLoading" /> -->
    <!-- <div v-if="!isLoading"> -->
      <div class="header-home shadow-sm">
        <b-row class="mx-0 header-row">
          <b-col class="sm-4 px-0"> </b-col>
          <b-col class="sm-4 px-0">
            <img
              alt="Vue logo"
              src="@/assets/login-logo.png"
              class="mt-2"
              style="width: 9rem"
            />
          </b-col>
          <b-col class="sm-4 px-0">
            <div class="mt-2 mr-3 d-none">
              <b-media right-align vertical-align="center" class="ml-0">
                <template #aside>
                  <img
                    alt="Vue logo"
                    src="@/assets/icon-token.png"
                    class="mt-1"
                    style="width: 2.5rem; height: 2.5rem"
                  />
                </template>
                <h4 class="mt-3 mb-1 font-weight-bold text-light text-right">
                  {{ coin !== null ? coin : 0 }}
                </h4>
              </b-media>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="home px-3">
        <!-- <h3 class="text-center font-weight-bold">ubgame.id</h3> -->
        <b-card-body class="card-body mb-3 p-0 roun shadow d-flex" style="border-radius:24px;background:linear-gradient(92.88deg, #120D50 4.2%, #A00B45 97.09%); border: none;">
          <div class="w-50 text-white">
            <b-media
              no-body
              tag="li"
              class="px-2 my-2 border-right"
              style="position:relative;"
            >
              <b-media-aside class="align-self-cente mr-1">
                <b-img
                  :src="require('@/assets/coin.png')"
                  alt="Media Aside"
                  style="width:3rem;height:3rem;"
                  class="m-2"
                ></b-img>
              </b-media-aside>
              <b-media-body class="align-content-between align-self-center d-flex flex-wrap">
                  <div>
                    <h6 class="mt-2 mb-0" style="text-align:left;font-size:.8rem">
                      Skor
                    </h6>
                    <h5 class="font-weight-bold" style="right: 1rem;">{{ point !== null ? point : 0 }}</h5>

                  </div>
                <!-- <div class="text-right">
                </div> -->
              </b-media-body>
            </b-media>
          </div>
          <div class="w-50 text-white">
            <b-media
              no-body
              tag="li"
              class="px-2 my-2 border-left"
              style="position:relative;"
            >
              <b-media-aside class="align-self-cente mr-1">
                <b-img
                  :src="require('@/assets/icon-token.png')"
                  alt="Media Aside"
                  style="width:3rem;height:3rem;"
                  class="m-2"
                ></b-img>
              </b-media-aside>
              <b-media-body class="align-content-between align-self-center d-flex flex-wrap">
                  <div>
                  <h6 class="mt-2 mb-0" style="text-align:left;font-size:.8rem">
                      Token
                    </h6>
                    <h5 class="font-weight-bold" style="right: 1rem;">{{ coin !== null ? coin : 0 }}</h5>
                  </div>
              </b-media-body>
            </b-media>
          </div>
            <!-- <h6 class="pb-2 d-block">Ayo tingkatkan lagi skormu!!</h6> -->
        </b-card-body>
        <div class="">
          <!-- <div class="mb-3">
          <swiper ref="promo" :options="swiperOptions">
            <swiper-slide v-for="(banner, index) in dataBanner" :key="index">
                <b-img
                  :src="banner.image"
                  fluid
                  rounded
                  class="w-100 px-2"
                  alt="Responsive image"
                  @click="goTo(banner.url)"
                ></b-img>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div> -->
        <div class="">
          <div class="leaderboard-header mb-3">
            <b-img
            :src="require('@/assets/bg-header.png')"
            fluid
            rounded
            alt="Responsive image"
          ></b-img>
          <h3 class="leaderboard-header-text">PERINGKAT HARI INI</h3>
          </div>
        <ListLeaderboard :listBoard="dataLeaderboard" />
        <b-button
          type="button"
          block
          variant="success"
          class="w-100 mb-4 btn-leaderboard"
          @click="goTo('leaderboard')"
          >
            <span>
              Lihat Leaderboard
            </span>
          </b-button
        >
        </div>
        
        </div>
        
        <h3 class="text-center font-weight-bold">Play & Win</h3>
        <Gallery />
      </div>
    <!-- </div> -->
  </section>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import Gallery from "@/components/Gallery.vue"
import ListLeaderboard from "@/components/ListLeaderboard.vue"
import router from '@/router'
// import Loader from '@/components/Loader.vue'
export default {
  name: "Home",
  components: {
    Gallery,
    ListLeaderboard,
    // Loader,
  },
  data() {
    return {
      swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      bannerList: [],
      // isLoading: true,
      },

      dataLeaderboard: [],
      dataBanner: [
        {
          name: 'Pesta Reward',
          image: require('@/assets/pesta-reward.png'),
          url: 'leaderboard',
        },
      ],
      dataProfile: null,
    };
  },
  mounted() {
    this.$store.state.isLoading = true
    this.getDetail().then(() => {
      this.getLeaderboard()
      this.$store.state.isLoading = false
      this.dataLeaderboard = this.homeLeaderboard()
    }, error => {
      console.error('err', error);
    })
  },
  computed: {
    ...mapState([
      'coin',
      'reward',
      'point',
      'leaderboardList',
    ])
  },
  methods: {
    handleSubmit() {},
    goTo(page) {
      router.push({ name: page });
    },
    ...mapActions([
      'getLeaderboard',
      // 'getGameReward',
      'getDetail',
      'getBanner',
      'getProfile'
      // 'getGames'
    ]),
    homeLeaderboard(){
      const lbList = this.leaderboardList;
      const newList = lbList.slice(0,3)
      // console.log(newList)
      return newList
      // this.dataLeaderboard = newList
    },
    homeBanner(){
      const blist = this.bannerList;
      return blist;
    }
  }
};
</script>

<style scoped>
body {
  background-color: white;
}
.header-home {
  /* height: 75px; */
  /* background: linear-gradient(90deg, #ff4401 0%, #ff0077 57.29%); */
  /* background-color: #fff; */
  /* background-color: #f7f7fc; */
  background: linear-gradient(#120D50, #0d093c);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}
.header-row {
  background-image: url("../assets/convetti.png");
  background-size: cover;
}
.home {
  padding-top: 6rem;
}

.leaderboard-header {
  position: relative;
}
.leaderboard-header-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bolder;
  font-size: 1.75rem;
  color: #7F5102;
}
.btn-leaderboard {
  font-size: 1rem;
  font-weight: bolder;
  margin: auto;
  border-radius: 16px;
}

.media-aside-right {
  margin-right: 0;
  margin-left: 0.5rem !important;
}
</style>
